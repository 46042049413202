import { hash, secretbox } from "tweetnacl";
import * as ed from '@noble/ed25519';

export interface ECTuple {
  Q: ed.RistrettoPoint,
  S: ed.RistrettoPoint
}

export interface BTuple {
  E: ed.RistrettoPoint,
  Ct: Uint8Array
}

export function SHA512(x: string) {
  var enc = new TextEncoder();
  return hash(enc.encode(x));
}

export function mapToCurve(x: string) {
  return ed.RistrettoPoint.hashToCurve(SHA512(x));
}

export function truncateString(str: string, len: number) {
  return str.slice(0, len / 2) + "..." + str.slice(str.length - (len / 2), str.length);
}

export const randomScalar = function () {
  return ed.utils.mod(BigInt("0x" + ed.utils.bytesToHex(ed.utils.randomPrivateKey())), ed.CURVE.l);
};

export function formatECP(x: ECTuple) {
  return truncateString(x.Q.toHex(), 12) + " & " + truncateString(x.S.toHex(), 12);
}