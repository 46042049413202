import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface HashTableProps {
  title: string;
  points?: string[];
  data: string[];
  color?: ("inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined)[];
  showPoints?: boolean;
  suffix?: string;
  titleColor?: string;
  visibleTo?: string;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function HashTable(props: HashTableProps) {
  const Item = styled(ListItem)(({ theme }) => ({
    padding: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const SetCard = styled(List)(({ theme }) => ({
    backgroundColor: "#EFF7F6",
    borderRadius: "0.5em",
    height: "10em",
    maxHeight: "10em",
    overflow: "scroll",
    marginTop: "0.5em",
  }));

  let titleElem;
  if (props.title.length > 0) {
    titleElem = <Box sx={{ textAlign: "center" }}><Typography variant="button" sx={{ color: props.titleColor, mx: "auto" }}>{props.title}</Typography></Box>;
  }

  return (
    <Container fixed>
      {titleElem}
      <LightTooltip title={"Visible to: " + props.visibleTo} placement="bottom">
        <SetCard dense={true}>
          {
            props.data.map((x, i) => <Item><ListItemText primary={x + props.suffix} secondary={props.showPoints ? props.points![i] : null} sx={{ color: props.color![i] + ".main" }} /></Item>)
          }
        </SetCard>
      </LightTooltip>
    </Container>
  );
}
