import * as React from 'react';
import * as ed from '@noble/ed25519';
import { hash, secretbox } from "tweetnacl";
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import HashTable from "./HashTable";
import * as crypto from "./Crypto";
import * as utils from "./Utils";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import GitHubIcon from '@mui/icons-material/GitHub';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import StarIcon from '@mui/icons-material/Star';
import RefreshIcon from '@mui/icons-material/Refresh';
import OutputIcon from '@mui/icons-material/Output';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const formatColor = [
  (x: string) => <Typography variant="button" color="secondary.main" fontSize="large">{x}</Typography>,
  (x: string) => <Typography variant="button" color="info.main" fontSize="large">{x}</Typography>,
  (x: string) => <Typography variant="button" color="success.main" fontSize="large">{x}</Typography>,
  (x: string) => <Typography variant="button" color="error.main" fontSize="large">{x}</Typography>,
  (x: string) => <Typography variant="button" color="warning.main" fontSize="large">{x}</Typography>
];

const partyNames = [
  () => formatColor[0]("Intelligence Community"),
  () => formatColor[1]("Email Service 1"),
  () => formatColor[2]("Email Service 2"),
  () => formatColor[3]("Email Service 3")
];

export default function App() {

  var setSize = 5;
  const [maxIdentifiers, setMaxIdentifiers] = React.useState(setSize);

  const genTables = function (intSize: number, setSize: number) {
    var tables = Array();
    var universe = Array();
    for (let i = 1; i < 4; i++) {
      tables[i] = utils.randomEnglish(setSize, "", "@email" + String(i) + ".com");
      universe = [...universe, ...tables[i]];
    }

    utils.shuffleArray(universe);
    tables[0] = universe.slice(0, intSize);
    tables[0] = [...tables[0], ...utils.randomEnglish(setSize - intSize, "", "@email4.com")];
    utils.shuffleArray(tables[0]);

    return tables;
  }

  const randomScalar = function () {
    return ed.utils.mod(BigInt("0x" + ed.utils.bytesToHex(ed.utils.randomPrivateKey())), ed.CURVE.l);
  };

  var intSizeData = 3;
  var tableData = genTables(intSizeData, setSize);
  const [intSize, setIntSize] = React.useState(intSizeData);
  const [tables, setTables] = React.useState(tableData);
  const [showPoints, setshowPoints] = React.useState(false);

  // EC Setup
  const G = crypto.mapToCurve("Generator G");
  var alphaData = randomScalar();
  var L = G.multiply(alphaData);
  const [alpha, setAlpha] = React.useState(alphaData.toString(16));

  const computeColors = function (mI: number): ("inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined)[] {
    var ret = Array(mI).fill("secondary");
    for (let i = 0; i < tables[0].length; i++) {
      for (let j = 1; j < 4; j++) {
        if (tables[j].indexOf(tables[0][i]) >= 0) {
          ret[i] = ["info", "success", "error"][j - 1];
        }
      }
    }
    return ret;
  };

  const colorMatched = function (x: Array<string>): ("inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined)[] {
    var ret = Array(x.length).fill("secondary");
    for (let i = 0; i < x.length; i++) {
      if (x[i] !== "Not matched") ret[i] = "warning";
    }
    return ret;
  };

  const delegateBlind = function (x: string, al: bigint) {
    return crypto.mapToCurve(x).multiply(al);
  };

  const computeM = function (x: Array<Array<string>>, al: bigint) {
    var pts = Array(x[0].length);
    for (let j = 0; j < x[0].length; j++) {
      pts[j] = delegateBlind(x[0][j], al);
    }
    return pts;
  };

  const [MData, setM] = React.useState(computeM(tableData, alphaData));

  const reduceDH = function (T: ed.RistrettoPoint, P: ed.RistrettoPoint) {
    var beta = randomScalar();
    var gamma = randomScalar();
    return {
      'Q': T.multiply(beta).add(G.multiply(gamma)),
      'S': P.multiply(beta).add(L.multiply(gamma))
    }
  };

  const randomTuple = function () {
    return {
      'Q': G.multiply(randomScalar()),
      'S': G.multiply(randomScalar()),
    }
  };

  const computeR = function (m: Array<ed.RistrettoPoint>, R: Array<crypto.ECTuple> | null, xi: string[], x0: string[], first: boolean) {
    var R_new = Array(x0.length);

    xi.map((s, i) => {
      var idx = x0.indexOf(s);
      if (idx >= 0) {
        R_new[idx] = reduceDH(crypto.mapToCurve(xi[i]), m[idx]);
      }
    });

    xi.map((s, i) => {
      if (R_new[i] === undefined) {
        if (first) R_new[i] = randomTuple();
        else R_new[i] = reduceDH(R![i].Q, R![i].S);
      }
    });

    return R_new;
  };

  const updateR = function (m: Array<ed.RistrettoPoint>, x: Array<Array<string>>) {
    var R = new Array(3);
    R[0] = computeR(m, null, x[1], x[0], true);
    R[1] = computeR(m, R[0], x[2], x[0], false);
    R[2] = computeR(m, R[1], x[3], x[0], false);
    return R;
  }
  const [RData, setR] = React.useState(updateR(MData, tableData));

  const computeB = function (r: Array<Array<crypto.ECTuple>>) {
    var B = new Array(r[2].length);
    for (let i = 0; i < B.length; i++) {
      var key = crypto.SHA512(r[2][i].S.toHex()).slice(0, 32);
      var ct = secretbox(Uint8Array.from([i]), crypto.SHA512(r[2][i].Q.toHex()).slice(0, 24), key);
      B[i] = { 'E': r[2][i].Q, 'Ct': ct };
    }
    utils.shuffleArray(B);
    return B;
  }

  const [BData, setB] = React.useState(computeB(RData));

  const delegateFinish = function (b: Array<crypto.BTuple>, al: bigint) {
    var ret = Array(b.length);
    for (let i = 0; i < b.length; i++) {
      var key = crypto.SHA512(b[i].E.multiply(al).toHex()).slice(0, 32);
      var pt = secretbox.open(b[i].Ct, crypto.SHA512(b[i].E.toHex()).slice(0, 24), key);
      ret[i] = (pt === null) ? "Not matched" : "Matched 🇺🇸";
    }
    return ret;
  };

  var resData = delegateFinish(BData, alphaData);
  const [UnblindedBData, setUnblindedB] = React.useState(resData);

  function computeResult(r: Array<string>) {
    var ret = 0;
    r.forEach((x) => (x === "Matched 🇺🇸") ? ret++ : ret + 0);
    return ret;
  };
  const [computedIntSize, setComputedIntSize] = React.useState(computeResult(resData))

  const recompute = function (x: Array<Array<string>>, al: bigint) {
    var m = computeM(x, al);
    setM(m);
    var r = updateR(m, x);
    setR(r);
    var b = computeB(r);
    setB(b);
    var res = delegateFinish(b, al);
    setUnblindedB(res);
    setComputedIntSize(computeResult(res));
  };

  const handleParameterChange = function (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    tableData = genTables(intSize, maxIdentifiers);
    setTables(tableData);
    recompute(tableData, alphaData);
  };

  const handleBlindingKey = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    alphaData = randomScalar();
    L = G.multiply(alphaData);
    recompute(tables, alphaData);
    setAlpha(alphaData.toString(16));
  };

  const setupParameters = () => {
    return (
      <Paper elevation={1} sx={{ pt: 2.5, pb: 1.5, width: "75%" }}>
        <Grid container spacing={0}>
          <Grid item xs={4} sx={{ mb: 1, paddingX: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="size-label" color="secondary">Table Size</InputLabel>
              <Select
                labelId="size"
                id="tableSize"
                value={String(maxIdentifiers)}
                label="Table Size"
                onChange={(event: SelectChangeEvent) => setMaxIdentifiers(parseInt(event.target.value))}
                color="secondary"
              >
                {[5, 10, 20, 30, 40, 50].map((x, i) => <MenuItem value={x}>{x}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} sx={{ mb: 1, paddingX: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="intersection-label" color="secondary">Intersection Size</InputLabel>
              <Select
                labelId="intersection"
                id="intersectionSize"
                value={String(intSize)}
                label="Intersection Size"
                onChange={(event: SelectChangeEvent) => setIntSize(parseInt(event.target.value))}
                color="secondary"
              >
                {(Array(maxIdentifiers + 1).fill(<MenuItem value={0}>0</MenuItem>)).map((x, i) => <MenuItem value={i}>{i}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <Box sx={{ pt: 1, display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <Button sx={{ mr: 1 }} variant="outlined" color="secondary"
                onClick={handleParameterChange} startIcon={<RefreshIcon />}>
                Regenerate
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    )
  };

  const setupRound = () => {
    return (
      <Paper elevation={1} sx={{ pt: 2, pb: 3 }} >
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <HashTable title="X0 (Intelligence Community)" titleColor="secondary.main" data={tables[0]} color={computeColors(maxIdentifiers)} showPoints={false} suffix="" visibleTo="IC"></HashTable>
          </Grid>
          <Grid item xs={3}>
            <HashTable title="X1 (Email Service 1)" titleColor="info.main" data={tables[1]} color={Array(maxIdentifiers).fill("info")} showPoints={false} suffix=" 🇺🇸" visibleTo="Email Service 1"></HashTable>
          </Grid>
          <Grid item xs={3}>
            <HashTable title="X2 (Email Service 2)" titleColor="success.main" data={tables[2]} color={Array(maxIdentifiers).fill("success")} showPoints={false} suffix=" 🇺🇸" visibleTo="Email Service 2"></HashTable>
          </Grid>
          <Grid item xs={3}>
            <HashTable title="X3 (Email Service 3)" titleColor="error.main" data={tables[3]} color={Array(maxIdentifiers).fill("error")} showPoints={false} suffix=" 🇺🇸" visibleTo="Email Service 3"></HashTable>
          </Grid>
        </Grid >
      </Paper >
    );
  }

  const protocolRound1 = () => {
    return (<Paper elevation={1} sx={{ pt: 2, pb: 3 }}>
      <Grid container spacing={0}>
        <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <LightTooltip title="Visible to: IC" placement="top">
            <TextField label="Blinding Key" value={crypto.truncateString(alpha, 52)} id="outlined" sx={{ width: "95%", ml: 2 }} InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyIcon />
                </InputAdornment>
              ),
            }} color="secondary" />
          </LightTooltip>

          <FormGroup row sx={{ mt: 1, ml: 2 }}>
            <Button sx={{ mr: 2 }} variant="outlined" color="secondary"
              onClick={handleBlindingKey} startIcon={<RefreshIcon />}>Regenerate</Button>

            <FormControlLabel
              control={
                <Checkbox
                  checked={showPoints}
                  onChange={(event) => { setshowPoints(event.target.checked); }}
                />
              }
              label="Show associated email addresses"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <HashTable title="M" titleColor="secondary.main" data={MData.map((x, i) => x.toHex())} color={Array(maxIdentifiers).fill("secondary")} showPoints={showPoints} points={tables[0]} suffix="" visibleTo="All parties"></HashTable>
        </Grid>
      </Grid>
    </Paper>);
  };

  const protocolRound2 = () => {
    return (<Paper elevation={1} sx={{ pt: 2, pb: 3 }}>
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <HashTable title="M" titleColor="secondary.main" data={MData.map((x, i) => crypto.truncateString(x.toHex(), 12))} color={Array(maxIdentifiers).fill("secondary")} showPoints={false} suffix="" visibleTo="All parties"></HashTable>
        </Grid>
        <Grid item xs={3}>
          <HashTable title="R1" titleColor="info.main" data={RData[0].map((x: crypto.ECTuple, i: number) => crypto.formatECP(x))} color={Array(maxIdentifiers).fill("info")} showPoints={false} suffix="" visibleTo="Email Service {1, 2}"></HashTable>
        </Grid>
        <Grid item xs={3}>
          <HashTable title="R2" titleColor="success.main" data={RData[1].map((x: crypto.ECTuple, i: number) => crypto.formatECP(x))} color={Array(maxIdentifiers).fill("success")} showPoints={false} suffix="" visibleTo="Email Service {2, 3}"></HashTable>
        </Grid>
        <Grid item xs={3}>
          <HashTable title="R3" titleColor="error.main" data={RData[2].map((x: crypto.ECTuple, i: number) => crypto.formatECP(x))} color={Array(maxIdentifiers).fill("error")} showPoints={false} suffix="" visibleTo="Email Service 3"></HashTable>
        </Grid>
      </Grid>
    </Paper>);
  };

  const protocolRound3 = () => {
    return (<Paper elevation={1} sx={{ pt: 2, pb: 3, width: "75%" }}>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <HashTable title="M" titleColor="secondary.main" data={MData.map((x, i) => crypto.truncateString(x.toHex(), 12))} color={Array(maxIdentifiers).fill("secondary")} showPoints={false} suffix="" visibleTo="All parties"></HashTable>
        </Grid>
        <Grid item xs={4}>
          <HashTable title="R (created by Service 3)" titleColor="error.main" data={RData[2].map((x: crypto.ECTuple, i: number) => crypto.formatECP(x))} color={Array(maxIdentifiers).fill("error")} showPoints={false} suffix="" visibleTo="Email Service 3"></HashTable>
        </Grid>
        <Grid item xs={4}>
          <HashTable title="B" titleColor="warning.main" data={BData.map((x: crypto.BTuple, i: number) => crypto.truncateString(x.E.toHex(), 12) + " & " + crypto.truncateString(ed.utils.bytesToHex(x.Ct), 12))} color={Array(maxIdentifiers).fill("warning")} showPoints={false} suffix="" visibleTo="IC, Email Service 3"></HashTable>
        </Grid>
      </Grid>
    </Paper>);
  };

  const protocolRound4 = () => {
    return (<Paper elevation={1} sx={{ py: 3, width: "75%" }}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <LightTooltip title="Visible to: IC" placement="right">
            <TextField label="Blinding Key" value={crypto.truncateString(alpha, 52)} id="outlined" sx={{ width: "95%", ml: 1.75, mb: 1 }} color="secondary" InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyIcon />
                </InputAdornment>
              ),
            }}
              variant="standard" />
          </LightTooltip>
        </Grid>
        <Grid item xs={4}>
          <HashTable title="B" titleColor="warning.main" data={BData.map((x: crypto.BTuple, i: number) => "(" + crypto.truncateString(x.E.toHex(), 12) + ", " + crypto.truncateString(ed.utils.bytesToHex(x.Ct), 12) + ")")} color={Array(maxIdentifiers).fill("warning")} showPoints={false} suffix="" visibleTo="IC, Email Service 3"></HashTable>
        </Grid>
        <Grid item xs={4}>
          <HashTable title="Result" titleColor="secondary.main" data={UnblindedBData.map((x: string, i: number) => x)} color={colorMatched(UnblindedBData)} showPoints={false} suffix="" visibleTo="IC"></HashTable>
        </Grid>
        <Grid item xs={4}>
          <LightTooltip title="Visible to: IC" placement="right">
            <TextField label="Computed Intersection Size" value={computedIntSize} id="outlined" sx={{ width: "85%", ml: 1.75, mb: 1 }} color="secondary" InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <OutputIcon />
                </InputAdornment>
              ),
            }}
              variant="standard" />
          </LightTooltip>
        </Grid>
      </Grid>
    </Paper>);
  }

  return (
    <Container fixed sx={{ backgroundColor: "#EFF7F6", borderRadius: "0.5em" }}>
      <Box sx={{ my: 10, flexGrow: 1 }}>
        <Typography variant="h3" gutterBottom sx={{ textAlign: "center", pt: 3, fontWeight: "bold" }}>
          Estimating Incidental Collection in<br />Foreign Intelligence Surveillance
        </Typography>

        <Typography variant="h4" gutterBottom sx={{ textAlign: "center", pt: 0 }}>
          Large Scale Multiparty Private Set Intersection with Union and Sum
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mb: 4 }}>
          <Grid container spacing={0}>
            <Grid item xs={4}></Grid>
            <Grid item xs={2} sx={{ textAlign: "center" }}>
              <Link href="https://www.usenix.org/system/files/sec22-kulshrestha.pdf" color="secondary"><Button variant="outlined" startIcon={<TextSnippetIcon sx={{ fontSize: 30 }} />} color="secondary">Paper</Button></Link>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: "center" }}>
              <Link href="https://github.com/citp/mps-operations"><Button variant="outlined" startIcon={<GitHubIcon sx={{ fontSize: 30 }} />} color="secondary">Code</Button></Link>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Box>

        <Divider />

        <Typography variant="button" display="block" gutterBottom sx={{ mt: 2, mb: -2, fontSize: 30 }}>
          Instructions
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ my: 2 }}>
          <StarIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;This example is a gross simplification. Please refer to the <Link href="https://www.usenix.org/system/files/sec22-kulshrestha.pdf" color="secondary.main">paper</Link> for the exact protocol.
          <br />
          <StarIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;In this example, there are 4 participants: {partyNames[0]()}, {partyNames[1]()}, {partyNames[2]()}, {partyNames[3]()}.
          <br />
          <StarIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;For simplicity, all participants have input tables of the same size.
          <br />
          <StarIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;Hover over each table to view a list of participants it is visible to.
          <br />
          <StarIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;Random tables can be generated by selecting the table and intersection sizes below.
        </Typography>

        {setupParameters()}

        <Typography variant="button" display="block" gutterBottom sx={{ mt: 2, mb: -2, fontSize: 30 }}>
          Setup
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ my: 2 }}>
          <StarIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;Each participant builds an input table consisting of email addresses.
          <br />
          <StarIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;{partyNames[0]()}'s input table {formatColor[0]('X0')} contains all addresses they incidentally collected emails to or from.
          <br />
          <StarIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;Service providers' input tables {formatColor[1]('X1')}, {formatColor[2]('X2')}, {formatColor[3]('X3')} contain all email addresses believed to be controlled by users located in the U.S. 🇺🇸
        </Typography>

        {setupRound()}

        <Typography variant="button" display="block" gutterBottom sx={{ mt: 2, mb: 0, fontSize: 30 }}>
          Protocol
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 0, mb: 2 }}>
          <LooksOneIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;
          The {partyNames[0]()} generates a random blinding key <VpnKeyIcon sx={{ mb: -0.25 }} fontSize="small" color="secondary" /> and blinds its input table as elliptic curve points in {formatColor[0]('M')}.
        </Typography>

        {protocolRound1()}

        <Typography variant="h6" gutterBottom sx={{ my: 2 }}>
          <LooksTwoIcon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;
          {partyNames[1]()} uses {formatColor[0]('M')}, {formatColor[1]('X1')} to build {formatColor[1]('R1')}. {partyNames[2]()} uses {formatColor[0]('M')}, {formatColor[2]('X2')}, {formatColor[1]('R1')} to build {formatColor[2]('R2')}. {partyNames[3]()} uses {formatColor[0]('M')}, {formatColor[3]('X3')}, {formatColor[2]('R2')} to build {formatColor[3]('R3')}. {formatColor[1]('R1')}, {formatColor[2]('R2')}, and {formatColor[3]('R3')} contain two elliptic curve points in each row.
        </Typography>

        {protocolRound2()}

        <Typography variant="h6" gutterBottom sx={{ my: 2 }}>
          <Looks3Icon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;
          {partyNames[3]()} uses {formatColor[0]('M')}, {formatColor[3]('R3')} to build a shuffled and encrypted table {formatColor[4]('B')}.
        </Typography>

        {protocolRound3()}

        <Typography variant="h6" gutterBottom sx={{ my: 2 }}>
          <Looks4Icon sx={{ mb: -0.25 }} fontSize="small" />&nbsp;
          {partyNames[0]()} computes the intersection size using {formatColor[4]('B')} and the blinding key <VpnKeyIcon sx={{ mb: -0.25 }} fontSize="small" color="secondary" />.
        </Typography>

        {protocolRound4()}

        <Typography variant="h6" gutterBottom sx={{ mt: 2, mb: -1 }}>
          No participant learns any other information.
        </Typography>

        <Typography variant="h6" gutterBottom>
          &nbsp;
        </Typography>

      </Box>
    </Container >
  );
}
